.App {
  text-align: center;
  width: 500px;
}

.cardThreeChart {
  height: 3rem !important;
}

.navbar {
  box-shadow: none;
}
.cursor-pointer {
  cursor: pointer;
}
.form-height {
  display: flex;

  height: 30px !important;
}

.form-field {
  height: 27px !important;
}
/* comment */
