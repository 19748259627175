.header-title-a2c-main {
  font-size: 2.3rem;
  font-style: normal;
  font-weight: 600;
  /* padding: 1rem 2rem 0rem 0.9rem; */
  width: 100%;
  color: #ff6a2f;
}

.filter-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 13px;
}

.filter-cards .ant-card {
  border-radius: 0.5625rem;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 468.03%
  );
  backdrop-filter: blur(5px);
}

.filter-cards .ant-card .ant-card-body {
  padding: 0px 10px 10px 10px;
}

.filter-cards .ant-card .ant-card-head {
  border: none;
  color: #ff6a2f;
  text-align: center;
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  min-height: 3rem;
  line-height: 1.38731rem;
  /* 92% */
}

.slm-table.ant-table-wrapper .ant-table-thead > tr > td {
  color: #ff6a2f;
  text-align: center;
  border: none;
}

.slm-table.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #202020 !important;
  border-top: 0.474px solid #84848484;
  border-bottom: transparent !important;
}

.slm-table .ant-pagination .ant-pagination-prev {
  border: 1px solid #848484;
  margin: 0px;
  padding-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.slm-table .ant-pagination .ant-pagination-item {
  border: 1px solid #848484;
  margin: 0px;
  border-radius: 0px;
}

.slm-table .ant-pagination .ant-pagination-next .ant-pagination-item-link,
.slm-table .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  display: flex;
  align-items: center;
  color: white;
}

.slm-table .ant-pagination .ant-pagination-next {
  border: 1px solid #848484;
  margin-right: 16px;
  padding-right: 7px;
  padding-left: 7px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.slm-table .ant-table-pagination.ant-pagination {
  background-color: #202020;
  margin-bottom: 2em;
  margin-top: 0;
  padding-bottom: 16px;
  padding-top: 13px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.slm-table.ant-table-wrapper .ant-table-thead > tr > th {
  color: #ff6a2f;
  background: #2e2e2e;
  min-height: 3rem;
  backdrop-filter: blur(5px);
  border: none;
  text-align: center;
}

.slm-table.ant-table-wrapper .ant-table {
  background-color: #202020;
  margin-top: 0.8rem;
  color: #ffffff;
  overflow-x: auto;
}

.slm-details-cards .ant-card .ant-card-head {
  border: none;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 0.9rem;
  padding: 0;
  font-weight: 400;
}

.slm-details-cards .ant-card .ant-card-body {
  padding: 5px;
  padding-top: 0;
  display: flex;
  justify-content: center;
}

.slm-details-cards .ant-card .ant-card-body .ant-col {
  border: none;
  color: #ff6a2f;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Poppins;
  padding: 0px 0.5rem 0px 0.5rem;
}

.slm-main-section {
  width: 70%;
  margin-top: 2rem;
}

.slm-main-section .ant-row {
  display: flex;
  /* flex-flow: row wrap; */
  min-width: 0;
}

.slm-details-cards {
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 13px; */
}

.slm-details-cards div {
  margin-right: 5px;
}

.slm-details-cards .ant-card .ant-card-body .ant-col .ant-row.details-header {
  color: #787878;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
}

.slm-details-cards .ant-card .ant-card-body .ant-col .ant-row.details-text {
  color: #ffffff;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
}

.slm-details-cards .ant-card {
  border-radius: 0.5625rem;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
  margin-bottom: 8px;
  width: 100%;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
}

.assess-cred-table {
  overflow-x: auto;
}

.operate-details-cards {
  border-radius: 0.5625rem;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
  margin: 8px 0;
  width: 100%;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
}

.operate-details-cards.ant-card .ant-card-head {
  border: none;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  padding: 0;
  font-weight: 400;
}

.operate-details-cards.ant-card .ant-card-body {
  border: none;
  color: #ff6a2f;
  text-align: center;
  font-size: 1.5625rem;
  font-weight: 600;
  font-family: Poppins;
  padding: 0px 1rem 1rem 1rem;
}

.operate-antd-select {
  border-radius: 0.3125rem;
  background: rgba(15, 15, 15, 0.5);
  width: 100%;
}

.operate-tabs .ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 2rem 0;
}

.operate-tabs .ant-tabs-tab-btn {
  color: #848484;
  text-align: center;
  font-family: Poppins;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.77413rem;
  padding: 0 2.5rem 0 2.5rem;
}

.operate-tabs .ant-tabs-nav-wrap .ant-tabs-nav-list {
  background-color: transparent;
  border-radius: 0.9375rem;
  border: 2px solid #2b2b2b;
}

.operate-tabs .ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #ff6a2f !important;
}

.operate-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  height: 0.4375rem;

  flex-shrink: 0;
  width: 12rem !important;
  margin-left: 3.3rem;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  background: #ff6a2f !important;
}

.operate-main-section {
  margin: 3rem 2rem 1rem 2rem;
  min-height: 100vh;
}

.operate-analysis-cards,
.operate-cards {
  border-radius: 0.5625rem;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
  margin: 8px 0 8px 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 468.03%
  );
  backdrop-filter: blur(5px);
}

.operate-analysis-cards.ant-card .ant-card-head,
.operate-cards.ant-card .ant-card-head {
  border: none;
  color: #ff6a2f;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
}

.operate-cards.ant-card .ant-card-body {
  border: none;
  padding: 0px 1rem 1rem 1rem;
}

.filter-dropdown-scroll {
  height: 330px !important;
  /* overflow-y: scroll !important; */
}

.tabss {
  position: relative;
}

/* added for new button */
.add-button-position {
  position: absolute;
  padding: 0.7rem;
}

button.add-btn {
  border-radius: 15px;
  border: 2px solid #2b2b2b;
  background: #ff6a2f;
  color: white;
  padding: 9px 17px 30px 12px;
  z-index: 111;
}

.ticketscategory {
  width: 100%;
}

.ticketapplication {
  width: 100%;
}

.ticketstatus {
  width: 100%;
}

.ticketseverity {
  width: 100%;
  min-height: 20rem;
}

.sev-chart {
  height: 300px;
}

.sla-chart {
  height: 305px;
}

.tableslm {
  width: 100%;
}

.a2cfilter .filter-dropdown-scroll {
  height: 310px !important;
  overflow-y: hidden !important;
}

.chart-header {
  color: #ff6a2f;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  padding: 1rem 0;
}

.pie-chart-height {
  height: 318px;
}

.spinner-height {
  height: 250px;
}

.bar-chart-height {
  height: 430px;
}

.mixed-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 300px; */
}

.semi-donut-chart {
  display: flex;
  position: relative;
}

.radial-chart {
  position: absolute;
  top: 52%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.sub-header {
  color: #ff6a2f;
  font-weight: 600;
}

.kubernetes-header {
  color: #ff6a2f;
  text-align: center;
  font-style: normal;
  font-weight: 600;
}

.titletab {
  color: white;
}

.counts {
  color: white;
  margin-left: 4.5rem;
  font-size: 16px;
}

.counts1 {
  color: white;
  margin-left: 8.6rem;
  font-size: 16px;
}

.progressbar .ant-progress .ant-progress-outer {
  display: inline-block;
  width: 50%;
  /* margin-left: 21rem; */
  margin-left: 20rem;
}

/* war css */
.buttonclass.ant-btn-default {
  background-color: #ff6a2f;
  border: none !important;
  /* box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02); */
  margin-left: 1rem;
}

.buttonclass.ant-btn {
  font-size: 14px;

  padding: 4px 15px;
  border-radius: 6px;
  color: white;
}

.question {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

h4.headingh4 {
  color: #ff6a2f;
  padding-top: 0rem;
  padding-left: 0rem;
  padding-bottom: 1rem;
}

.whitecolor {
  color: #ffffff !important;
  padding: 1rem;
  line-height: 2.7;
}

.textareacls.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5714285714285714;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  resize: vertical;
  background: transparent;
  color: white;
}
.ant-input {
  box-sizing: content-box;
  margin: 0px;
  padding: 4px 11px;
  color: white;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  background-color: black;
  background-image: none;
  border-style: none;
  border-radius: 6px;
}
.savebutton {
  background-color: #202020;
  color: #fff;
}

.savebutton .ant-btn-default:disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  /* color: rgba(0, 0, 0, 0.25); */
  /* background-color: rgba(0, 0, 0, 0.04); */
  box-shadow: none;
}

.progressvalue .ant-progress .ant-progress-bg {
  position: relative;
  background-color: #ff6a2f !important;
  border-radius: 100px;
  min-height: 10px;
}

.gradient-container {
  width: 100%;
  height: 100px; /* Adjust the initial height as needed */
  background: linear-gradient(to right, #ff6a2f 99%, white 99%);
}
.war-progress .ant-progress-bg {
  height: 6px !important;
}

.perform {
  color: white;
  align-items: start;
}

.btn-perform {
  background-color: #ff6a2f;
  border-radius: 10px;
  height: 28px;
  margin-left: 10px;
}
.font-of-qus {
  font-size: 16px !important;
}

.perform-header {
  display: flex;
  justify-content: flex-start;
}

.card-perform-question {
  color: "grey";
}

.question {
  padding: 5px;
  line-height: 1.7;
  font-size: 1rem;
}

.perform-white {
  color: white;
  font-size: 15px;
}

.perform-cards {
  border-radius: 0.5625rem;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
  margin: 8px 0 8px 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 468.03%
  );
  backdrop-filter: blur(5px);
}

.perform-cards .ant-card .ant-card-head {
  border: none;
  color: #ff6a2f;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
}

.perform-cards .ant-card .ant-card-body {
  border: none;
  padding: 21px 1rem 1rem 1rem;
}

.progress-score .ant-progress.ant-progress-show-info .ant-progress-outer {
  margin-inline-end: calc(-2em - 8px);
  padding-inline-end: calc(2em + 8px);
  margin-top: 0rem;
}

.progress-score .ant-progress .ant-progress-bg {
  position: relative;
  background-color: #ff6a2f;
  border-radius: 100px;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.progress-score .ant-progress .ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 100px;
  /* margin-top: 1.5rem; */
}

/* .war-body-tabs {
  padding-right: 10%;
} */
.progress-score .ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
  /* margin-inline-end: 8px; */
  margin-bottom: 0px;
}
.answer-card {
  width: 100%;
}

.nosprod-card.ant-card .ant-card-body {
  padding: 24px;
  border-radius: 0 0 8px 8px;
  display: flex;
  border-radius: 0.5625rem;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
  background-color: rgba(255, 255, 255, 0.06);
  margin: 8px 0 8px 0;
  width: 100%;
}

.donut-war {
  height: 262px;
}

.delbtn .ant-btn {
  font-size: 14px;
  padding: 4px 15px;
  border-radius: 6px;
  color: white;
}

.answer-card {
  width: 100%;
}
.slm-table .ant-table-content > table > thead > tr > th::before {
  display: none;
}

.ant-table-column-sorter.ant-table-column-sorter-full
  .ant-table-column-sorter-inner:hover {
  color: red !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #787878;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4a4747;
}

.a2c-rover-sidebar {
  position: fixed;
}

main.position-main-div {
  /* width: 86vw; */
  margin-left: 14rem;
}

.color-orange {
  color: #ff6a2f;
}

.margin-right-1 {
  margin-right: 1rem;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomRight {
  background: #2e2e2e;
}
.ant-table-wrapper .ant-table-column-sorter .ant-table-column-sorter-inner {
  z-index: 1111;
  position: relative;
}

.cams-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  white-space: normal;
}

.border-line {
  border-radius: 15px;
  border: 2px solid #2b2b2b;
  padding: 1rem;
}

p.progress-percentage {
  margin: 0;
  text-align: start;
}

.cost-analysis-tabs-width {
  width: 83vw;
}

.slm-tabs-width {
  width: 48.5%;
}

.header-title-a2c-main1 {
  font-size: 2.3rem;
  font-style: normal;
  font-weight: 600;
  padding: 1rem 2rem 0rem 0.9rem;
  width: 100%;
  color: #ff6a2f;
}
.costmodalnew.ant-modal .ant-modal-content {
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  box-shadow: -8px 11px 43px 0px rgba(0, 0, 0, 0.25);
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 35px 29px;
  background-color: rgba(0, 0, 0, 0.08) !important;
  margin-top: -23%;
}

.fileupload {
  margin-left: 29%;
}
.uploadbutton {
  float: right;
}
.costmodalnew .uploadbutton button {
  width: 5%;
}
.costmodalnew {
  margin-top: 20%;
}
.ant-modal {
  color: antiquewhite;
}
.ant-progress span.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-inline-start: 8px;
  color: white;
  line-height: 1;
  white-space: nowrap;
  text-align: start;
  vertical-align: middle;
  word-break: normal;
}
.questionopt {
  font-size: 1rem;
}

.ant-progress.ant-progress-circle .ant-progress-text {
  color: white;
}

.scroll-container {
  overflow-y: scroll;
  max-height: 400px;
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
}

.scroll-container {
  scrollbar-width: thin;
}

.form-check-input {
  background-color: white;
}

.form-check-input:checked {
  background-color: #ff6a2f;
}

.war_switch {
  background-color: grey;
}
.ant-picker-range {
  background: #fff;
}

.ant-picker .ant-picker-input > input {
  color: #0000;
}

.costimize-body-screen.padding-right {
  padding: 0 6rem 1rem 1rem;
}
.date-picker-input {
  background: #fff;
  padding: 5px 10px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
  border-radius: 7px;
  color: #000;
}
.costimize-body-screen.report-screen-width {
  width: 99vw;
}

.ant-radio-wrapper span.ant-radio + * {
  color: white;
}

.ant-table-wrapper.last-col-color
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 8px;
  background: #2e2e2e;
}

.ant-input.background-antd-color {
  background-color: #2e2e2e;
  color: white;
  width: 80%;
}

.ant-form-item .ant-form-item-label > label {
  color: white;
}

.annual-card-height {
  height: 22.3rem;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: #2e2e2e;
}

.operate-cards.ant-card.annual-cost-savings
  .ant-card-head
  .ant-card-head-title {
  color: white;
}
