.budget-planning-cards {
    box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
    border-radius: 11.1579px;
    height: 15rem !important;
}

.DashCard-rentStatus {
    background-color: #FFFFFF !important;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    max-width: 310px !important;
}

.budget-float-right {
    border-radius: 6px;
    border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
    /* background: #2e2e2e; */
    /* -webkit-backdrop-filter: blur(5px); */
    /* backdrop-filter: blur(5px); */
    border: none;
    color: #fff;
    margin: 6px;
    padding: -1px 22px;
    float: right;
}

.budgetPlan-card-title {
    font-weight: 500;
    font-size: inherit;
    line-height: 25px;
    /* color: #000000; */
    text-align: center;
    color: #FFFFFF;
}

.submit-btn-grad {
    background: linear-gradient(261deg, #FF6A2F 26.19%, #FFD380 168.97%) !important;

}

.pieHead {
    text-align: center !important;
    font-size: 15px !important;
    margin-bottom: 1rem;
}

.chart-skills-rentStatus {
    margin-top: 0rem !important;
}

.budgetText {
    position: absolute;
    left: 10rem;
    top: 11.75rem;
    /* font-weight: 600 !important; */
}

.budgetText>span {
    font-weight: 400;
    font-size: 13px;
    margin-left: 1rem;
    margin-top: 0.75rem;
}

.TextPie1 {
    font-weight: 500 !important;
    font-size: 13px !important;
}

.chart-skills-rentStatus::after {
    content: '92%';
    left: 52%;
    bottom: 1rem !important;
    transform: translateX(-50%);
    font-size: 1.25rem !important;
    font-weight: 400 !important;
    color: #373737 !important;
    font-family: "Montserrat", sans-serif;
}

.TextPie1 {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    margin: 5px;
    font-weight: 500;
}

.budgetPlanningCardCount {
    font-weight: 700 !important;
    margin-top: 0.5rem !important;
}

.budgetPlanCardIcon {
    margin-top: 0.5rem !important;
    color: #433f3f;
}

.budgetPlanCardHeader {
    margin-top: 1.5rem !important;
}

.apexcharts-toolbar {
    display: none !important;
}

.remainingBudget_green {
    color: "#3D9D1B" !important;
}


.applicationBudgetTable thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

.applicationBudgetTable tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

.annualBudgetTable thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

.annualBudgetTable tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

.plannedVsActualTable thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

.plannedVsActualTable tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

.applicationBudgetTable thead th:nth-child(18) {
    position: sticky;
    right: 0;
    z-index: 2;
}


.applicationBudgetTable tbody td:nth-child(18) {
    position: sticky;
    right: 0;
    z-index: 2;
}

.annualBudgetTable thead th:nth-child(15) {
    position: sticky;
    right: 0;
    z-index: 2;
}

.annualBudgetTable tbody td:nth-child(15) {
    position: sticky;
    right: 0;
    z-index: 2;
}

.plannedVsActualTable thead th:nth-child(15) {
    position: sticky;
    right: 0;
    z-index: 2;
}

.plannedVsActualTable tbody td:nth-child(15) {
    position: sticky;
    right: 0;
    z-index: 2;
}






/* media query */
@media only screen and (max-width: 2560px) and (min-width: 2305px) {}

@media only screen and (max-width: 2304px) and (min-width: 2161px) {}

@media only screen and (max-width: 2160px) and (min-width: 1922px) {}

@media only screen and (max-width: 1921px) and (min-width: 1681px) {}

@media only screen and (max-width: 1680px) and (min-width: 1601px) {}

@media only screen and (max-width: 1600px) and (min-width: 1471px) {}

@media only screen and (max-width: 1470px) and (min-width: 1441px) {}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {}

@media only screen and (max-width: 1280px) and (min-width: 1081px) {}

@media only screen and (max-width: 1080px) and (min-width: 1025px) {}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
    .budgetPlanningCardCount {
        font-weight: 700 !important;
        margin-top: 0rem !important;
    }

    .budgetPlanCardIcon {
        margin-top: 0rem !important;
        color: #433f3f;
    }

    .budgetPlanCardHeader {
        margin-top: 0rem !important;
    }

    .budgetText {
        position: absolute;
        left: 7.25rem;
        top: 11.75rem;
        font-size: 0.95rem;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1025px) {
    .costimize-body-screen {
        padding: 0rem 0rem !important;
    }

    .budgetPlanCardHeader {
        width: 9rem !important;
    }

    .projectIcon {
        margin-top: 2rem !important;
    }
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
    .costimize-header-body {
        width: 100%;
        position: fixed;
        margin-left: 0rem;
        padding: 0 3.9rem 0 3.5rem;
        z-index: 2;
        background-color: var(--costimize_background_color);
    }

    .header-filter-block {
        text-align: center;
    }

    .sidebar-mobile-main {
        z-index: 3;
        position: fixed;
        padding: 12px;
        margin-top: 0px;
    }

    .costimize-body-screen {
        margin-top: 7.5rem;
        margin-left: 0.7rem;
        padding: 0px 3.3rem;
        width: 100%;
    }

    .plat-card-3 .ant-card-body {
        margin: 0px !important;
        padding: 0px !important;
        height: 172px !important;
    }

    .budgetText {
        left: 22.25rem !important;
    }

    .budgetPlanningSemiDonutChart>.ant-card-body {
        display: flex !important;
        justify-content: center !important;
    }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
    .costimize-header-body {
        width: 100%;
        position: fixed;
        margin-left: 0rem;
        padding: 0 3.9rem 0 3.5rem;
        z-index: 2;
        background-color: var(--costimize_background_color);
    }

    .sidebar-mobile-main {
        z-index: 3;
        position: fixed;
        padding: 12px;
        margin-top: 0px;
    }

    .costimize-body-screen {
        margin-top: 7.5rem;
        margin-left: 0.7rem;
        padding: 0px 3.3rem;
        width: 100%;
    }

    .header-filter-block {
        text-align: center;
    }

    .plat-card-3 .ant-card-body {
        margin: 0px !important;
        padding: 0px !important;
        height: 172px !important;
    }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
    .costimize-header-body {
        width: 100%;
        position: fixed;
        margin-left: 0rem;
        padding: 1rem 1.5rem 0;
        background-color: var(--costimize_background_color);
        z-index: 2;
    }

    .sidebar-mobile-main {
        z-index: 3;
        position: fixed;
        padding: 12px;
        margin-top: 53px;
    }

    .costimize-body-screen {
        margin-top: 8.5rem;
        margin-left: 0.7rem;
        padding: 0px 1.3rem;
        width: 100%;
    }

    .header-filter-block {
        text-align: center;
    }

    .plat-card-3 .ant-card-body {
        margin: 0px !important;
        padding: 0px !important;
        height: 172px !important;
    }
}

@media (max-width: 320px) {
    .costimize-header-body {
        width: 100%;
        position: fixed;
        margin-left: 0rem;
        padding: 1rem 1.5rem 0;
        background-color: var(--costimize_background_color);
        z-index: 2;
    }

    .sidebar-mobile-main {
        z-index: 3;
        position: fixed;
        padding: 12px;
        margin-top: 51px;
    }

    .costimize-body-screen {
        margin-top: 8.5rem;
        margin-left: 0.7rem;
        padding: 0px 1.3rem;
        width: 100%;
    }

    .header-title {
        font-weight: 600;
        font-size: 18px;
        margin-top: 5px;
        /* text-align: center; */
    }

    .header-logo-block {
        text-align: center;
    }

    .plat-card-3 .ant-card-body {
        margin: 0px !important;
        padding: 0px !important;
        height: 172px !important;
    }

    /* .header-filter-block {
        text-align: center;
    } */
    /* .header-filter-list li {
        display: block;
        padding: 0;
        margin: 0 0 0 20px;
        cursor: pointer;
        font-weight: 500;
        font-size: 12.24px;
        color: var(--costimize_site_color);
    } */
}

@media only screen and (min-width: 2560px) {
    .costimize-table-width {
        width: 95vw !important;
    }

    .budgetText {
        left: 19.25rem !important;
    }

    .budgetPlanningSemiDonutChart>.ant-card-body {
        display: flex !important;
        justify-content: center !important;
    }
}