.cams-header-body {
  padding: 1rem 1rem;
}

.header-title.header-color {
  color: #ff6a2f;
  font-family: Poppins;

  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  font-size: 22px;
  left: 101px;
}
h6.card-title {
  color: #fff;
}
/* .cams-content {
  background: #f8f8f8;
} */
.cams-content-inner {
  width: 90%;
}
.cams-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  background: transparent;
}
.alert-detail-table .slm-table .ant-table-pagination.ant-pagination {
  margin-bottom: 0;
}
.header-filter {
  position: relative;
}
.customer-select {
  width: 11.5rem;
}
.spinner-class {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reports-submit {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background-color: #1c344c;
  color: white;
}

.customer-filter {
  width: 10rem;
  position: absolute;
  top: 4.5rem;
  right: 2rem;
  z-index: 1;
}
.ant-tabs-nav-list {
  border: 0;
}
.ant-tabs-tab {
  margin: 0px !important;
  background: transparent !important;
}
.ant-tabs-tab.ant-tabs-tab-active {
  background: transparent !important;
}
.ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  /* color: #1c344c !important; */
  font-weight: 600;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
  background: transparent !important;
  /* font-weight: 500; */
}
.cams-tabs .ant-tabs-tab-btn {
  color: #ff6a2f !important;
  padding: 1rem 2.9rem;
  font-size: 1.19425rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.83119rem !important;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  /* background: #1c344c !important; */
  background-color: #fff !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px;
}
.alert-detail-table {
  padding: 0.8rem 0rem;
}

.cams-global-filter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-content: flex-start;
  flex-wrap: wrap-reverse;
}
.alert-detail-table .table > :not(caption) > * > *,
.summary-view-table .table > :not(caption) > * > * {
  padding: 0.3rem 0.8rem;
}
.alert-detail-table .table,
.summary-view-table .table {
  color: #fff;
  border-bottom: 1px solid #84848484;
  border-radius: 5px;
  margin: 0;
  background-color: #202020;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  font-size: 12px;
}
.top-filter {
  padding-top: 0.3rem;
}
.summary-view-table .react-bootstrap-table::-webkit-scrollbar {
  background: transparent;
  width: 7px;
}
.summary-view-table .react-bootstrap-table::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 7px;
}
.top-filter-input {
  width: 100%;
  /* border: 1px solid #d2d2d2;
  border-radius: 5px;
  padding: 0.3rem 1rem; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}

.alert-detail-table > .react-bootstrap-table {
  height: 350px;
  overflow-y: auto;
  /* width: 591px; */
}

.alert-detail-table-reports > .react-bootstrap-table {
  height: 350px;
  overflow-y: auto;
  margin-top: 1rem;
  /* width: auto; */
}

.alert-detail-table-reports .table > :not(caption) > * > * {
  padding: 0.3rem 0.8rem;
}
.table-section {
  overflow-y: hidden;
}
.summary-view-table > .react-bootstrap-table {
  height: 200px;
  overflow-y: auto;
  border-radius: 5px;
}

.react-bootstrap-table th {
  position: sticky;
  top: 0;
}

.react-bootstrap-table tfoot {
  position: sticky;
  bottom: 0;
}
span.alert-info {
  font-size: 10px;
  background-color: transparent;
  color: #fff;
}
.card-value-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.summary-view .plat-card-1 {
  height: 10.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plat-card-table {
  border-radius: 11.1579px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2)) !important;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
}

.alert-detail-table table > thead {
  background: #2e2e2e;
  color: white;
  position: sticky;
  top: 0;
}

.alert-detail-table2-height {
  /* height: 300px; */
  overflow-y: auto;
}
.reports-table {
  display: block !important;
  width: 100%;
}
.export {
  cursor: pointer;
  margin: 1.5rem 0rem 1rem 1rem;
}

.export span {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
  padding: 0.4rem 1rem;
}

.info-icon-position {
  position: absolute;
  right: 2em;
  top: 7.2em;
  z-index: 1;
  /* margin-top: 15px !important; */
  margin-right: -1% !important;
}
.reports-spinner {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 21rem;
}
.circle-critical,
.circle-warn,
.circle-unreachable,
.circle-unknown,
.circle-up,
.circle-down,
.circle-ok {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
.circle-critical {
  background-color: #ff0000;
}

.circle-warn {
  background-color: #eb5c24;
}

.circle-unreachable {
  background-color: #0e365a;
}
.circle-unknown {
  background-color: #848484;
}
.circle-up {
  background-color: #86bc0b;
}
.circle-ok {
  background-color: #ffaa00;
}
.circle-down {
  background-color: #2a87db;
}
.page-item.active .page-link {
  background-color: #1c344c !important;
  z-index: 0 !important;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s linear;
}
.summary-info-icon-position {
  position: absolute;
  right: 3.5em;
  top: 4.2em;
  z-index: 1;
}

.icon-div {
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: 1rem;
  border-radius: 5px;
  width: auto;
  padding: 0.2rem;
}
.cams-date {
  /* width: 12rem; */
  margin-right: 0.5rem;
}
.cams-date .ant-picker {
  /* background-color: #fff; */
  background: transparent;
  color: white;
}
.cams-date .ant-picker .ant-picker-input > input,
.cams-date .ant-picker .ant-picker-input > input:focus,
.cams-date .ant-picker .ant-picker-input > input::placeholder {
  color: #ff6a2f;
  margin: 0px;
  text-align: center;
  font-size: 1rem;
  width: 10rem;
}

.icon-shadow {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.zoho-popup {
  width: 50%;
  margin-left: 22rem;
}
.zoho-info {
  color: #082648;
  margin-top: 1rem;
}
.alert-text {
  float: right !important;

  color: #fff;

  font-size: 0.7rem;
}

.smart-alert-text {
  font-size: 0.75rem !important;

  cursor: pointer !important;
}
@media only screen and (max-width: 600px) {
  .ant-tabs-tab-btn {
    padding: 0rem 1rem;
  }
  .header-title.header-color {
    color: #2e2e2e;
    font-size: 14px;
  }
  .summary-view .plat-card-1 {
    height: 8rem;
  }
}

.cams-tabs .ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #ff6a2f !important;
}
.table-width-alert {
  width: 20%;
}
